/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  background-color: #000;
  p {
    font-size: 0.9rem;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  ul {
    list-style-type: none;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
.skip-link {
  color: #fff !important;
  background: $theme-primary !important;
  padding: 10px 20px !important;
  border-bottom: none !important;
  text-decoration: underline !important;
}
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;

  a.logo {
    float: left;
    border: 0;
    max-width: 650px;
    padding: 5px 0;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  .logo-box {
    max-width: 75%;
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}

#search {
  .btn-success {
    background-color: $theme-primary;
    border-color: $theme-primary;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      background: darken($theme-primary, 7);
      border-color: darken($theme-primary, 7);
    }
  }
}

#banner-wrap {
  h1,
  p {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  }
  &.home {
    background: #0c6a38 url(/images/hero/florida-traffic-school.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.approved {
    background: #0c6a38 url(/images/hero/florida-driving-records.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.map {
    background: #0c6a38 url(/images/hero/123driving-site-map.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.handbook {
    background: #0c6a38 url(/images/hero/florida-dmv.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.help {
    background: #0c6a38 url(/images/hero/traffic-school-help.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.counties {
    background: #0c6a38 url(/images/hero/florida-counties.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.defensive-driving {
    background: #0c6a38 url(/images/hero/defensive-driving-florida.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
  &.permit {
    background: #0c6a38 url(/images/hero/florida-learners-permit.jpg) no-repeat 50% 50%;
    background-size: cover;
  }
}

#stats,
#about {
  border: 1px solid #dcf5dc;
  font-size: 0.9rem;
}

#stats img {
  max-width: 190px;
}

#about .icon {
  width: 50px;
}

#search {
  input {
    &:focus {
      box-shadow: none;
    }
  }
  button {
    &:focus {
      box-shadow: none;
    }
    img {
      max-width: 21px;
    }
  }
}
#addsearch-results #addsearch-results-mid {
  margin-top: -25px;
  .addsearch-result-item-sub-active {
    background-color: #fff;
  }
  .addsearch-result-item-sub h2 a {
    color: $link-color;
  }
}

hr.border {
  border: 1px solid #c5cacf !important;
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 16px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background: $cta-green !important;
    border: 0;
    height: auto;
    line-height: 1;
    font-size: 21px;
    text-align: center;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 50px;
      width: 220px !important;
      li a {
        color: #333 !important;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: darken($cta-green, 7) !important;
    }
  }
  &.small {
    span.dropdown {
      padding: 14px 0 11px 0;
      font-size: 18px;
      & + .drop {
        top: 42px;
      }
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 1.9em;
    }
    span.small {
      display: inline-block;
    }
  }
  .border-md-0 {
    border: 0 !important;
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    span.small {
      font-size: 18px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrap {
    #header #support {
      display: flex;
    }
  }
}
